export const CarIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M12.5018 19.8932H12.4984H6.28983V22.0339C6.28983 24.6398 2.83749 24.6709 2.83749 22.0339V19.8932H1V13.1894C1 11.3901 2.32538 10.4392 3.07384 10.3354L4.96332 5.0663C5.319 4.06293 6.08964 3.20534 7.54199 3.20534H10.2415V1.82963C10.2415 1.27939 10.5148 1 10.9594 1H12.4984H12.5018H12.4981H12.5016H14.0702C14.5148 1 14.7881 1.27939 14.7881 1.82963V3.20534H17.4566C18.909 3.20534 19.6796 4.06293 20.0353 5.0663L21.9248 10.3354C22.6733 10.4392 24 11.3901 24 13.1894V19.8932H22.1625V22.0339C22.1625 24.6709 18.7102 24.6398 18.7102 22.0339V19.8932H12.5016H12.4981H12.5018Z"
        stroke={props.fill}
        stroke-opacity="0.4"
      />
      <path
        d="M12.4981 10.2113H12.4946H5.03003L6.45262 6.13441C6.63046 5.5518 6.89734 5.13104 7.51978 5.12476H12.4946H12.4981H12.5016H17.4789C18.1013 5.13104 18.3681 5.5518 18.5459 6.13441L19.9685 10.2113H12.5016H12.4981Z"
        stroke={props.fill}
        stroke-opacity="0.4"
      />
      <path
        d="M4.61861 15.795C3.73795 15.795 3.02393 14.9994 3.02393 14.0181C3.02393 13.0368 3.73795 12.2412 4.61861 12.2412C5.49927 12.2412 6.21323 13.0368 6.21323 14.0181C6.21323 14.9994 5.49927 15.795 4.61861 15.795Z"
        stroke={props.fill}
        stroke-opacity="0.4"
      />
      <path
        d="M20.3801 15.795C21.2607 15.795 21.9747 14.9994 21.9747 14.0181C21.9747 13.0368 21.2607 12.2412 20.3801 12.2412C19.4994 12.2412 18.7854 13.0368 18.7854 14.0181C18.7854 14.9994 19.4994 15.795 20.3801 15.795Z"
        stroke={props.fill}
        stroke-opacity="0.4"
      />
    </svg>
  );
};
