export const HomeIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
    >
      <path
        d="M22.7197 9.92304C22.556 9.92193 22.3968 9.867 22.2642 9.76593L11.7249 1.84401L1.18555 9.76593C1.01646 9.87137 0.815983 9.90623 0.623679 9.86364C0.431376 9.82104 0.261226 9.70409 0.146819 9.53585C0.0324115 9.36762 -0.0179382 9.16033 0.00570888 8.95492C0.029356 8.74951 0.125281 8.56089 0.274549 8.42631L11.2694 0.157082C11.4024 0.0573683 11.5616 0.00378418 11.7249 0.00378418C11.8881 0.00378418 12.0474 0.0573683 12.1804 0.157082L23.1752 8.42631C23.3093 8.52852 23.4093 8.67273 23.4609 8.83834C23.5125 9.00395 23.5131 9.1825 23.4625 9.34848C23.412 9.51446 23.313 9.6594 23.1796 9.76258C23.0462 9.86577 22.8852 9.92193 22.7197 9.92304Z"
        fill={props.fill}
        fill-opacity="0.4"
      />
      <path
        d="M3.08613 4.96154C2.87784 4.96154 2.67808 4.87442 2.5308 4.71934C2.38352 4.56426 2.30078 4.35393 2.30078 4.13462V0.826923C2.30078 0.607609 2.38352 0.397278 2.5308 0.2422C2.67808 0.087122 2.87784 0 3.08613 0H6.2275C6.43579 0 6.63554 0.087122 6.78282 0.2422C6.9301 0.397278 7.01285 0.607609 7.01285 0.826923C7.01285 1.04624 6.9301 1.25657 6.78282 1.41165C6.63554 1.56672 6.43579 1.65385 6.2275 1.65385H3.87147V4.13462C3.87147 4.35393 3.78873 4.56426 3.64145 4.71934C3.49417 4.87442 3.29441 4.96154 3.08613 4.96154Z"
        fill={props.fill}
        fill-opacity="0.4"
      />
      <path
        d="M18.793 21.5H14.8663C14.658 21.5 14.4582 21.4129 14.311 21.2578C14.1637 21.1027 14.0809 20.8924 14.0809 20.6731V14.8846H9.36888V20.6731C9.36888 20.8924 9.28614 21.1027 9.13886 21.2578C8.99158 21.4129 8.79182 21.5 8.58353 21.5H4.65681C4.03196 21.5 3.43269 21.2387 2.99085 20.7734C2.54901 20.3082 2.30078 19.6772 2.30078 19.0192V10.75C2.30078 10.5307 2.38352 10.3204 2.5308 10.1653C2.67808 10.0102 2.87784 9.9231 3.08613 9.9231C3.29441 9.9231 3.49417 10.0102 3.64145 10.1653C3.78873 10.3204 3.87147 10.5307 3.87147 10.75V19.0192C3.87147 19.2386 3.95421 19.4489 4.10149 19.604C4.24877 19.7591 4.44853 19.8462 4.65681 19.8462H7.79819V14.0577C7.79819 13.8384 7.88093 13.6281 8.02821 13.473C8.17549 13.3179 8.37525 13.2308 8.58353 13.2308H14.8663C15.0746 13.2308 15.2743 13.3179 15.4216 13.473C15.5689 13.6281 15.6516 13.8384 15.6516 14.0577V19.8462H18.793C19.0013 19.8462 19.201 19.7591 19.3483 19.604C19.4956 19.4489 19.5784 19.2386 19.5784 19.0192V10.75C19.5784 10.5307 19.6611 10.3204 19.8084 10.1653C19.9557 10.0102 20.1554 9.9231 20.3637 9.9231C20.572 9.9231 20.7717 10.0102 20.919 10.1653C21.0663 10.3204 21.149 10.5307 21.149 10.75V19.0192C21.149 19.6772 20.9008 20.3082 20.459 20.7734C20.0171 21.2387 19.4179 21.5 18.793 21.5Z"
        fill={props.fill}
        fill-opacity="0.4"
      />
    </svg>
  );
};
